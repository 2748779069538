import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "RubyKaigi 2019 にメドレーが Breakfast Sponsor として参加しました",
  "date": "2019-04-24T08:54:25.000Z",
  "slug": "entry/2019/04/24/175425",
  "tags": ["medley"],
  "hero": "./2019_04_24.png",
  "heroAlt": "ruby kaigi 2019"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。開発本部のエンジニア・新居です。`}</p>
    <p>{`メドレーは 4/18〜4/20 に開催された RubyKaigi 2019 に Breakfast Sponsor（朝食スポンサー）として協賛させていただきました（`}<a parentName="p" {...{
        "href": "/entry/2017/09/28/120000"
      }}>{`一昨年の Ruby Sponsor`}</a>{`、`}<a parentName="p" {...{
        "href": "/entry/2018/06/06/151300"
      }}>{`昨年の`}</a><a parentName="p" {...{
        "href": "/entry/2018/06/06/151300"
      }}>{`Lightning Talks Sponsor`}</a>{`に続き、3 年目の協賛です）。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="RubyKaigi 2019" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Frubykaigi.org%2F2019" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://rubykaigi.org/2019">rubykaigi.org</a></cite>
    <h2>{`2017 年のレポート`}</h2>
    <iframe className="embed-card embed-blogcard" style={{
      "display": "block",
      "width": "100%",
      "height": "190px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="RubyKaigi 2017 にメドレーが Ruby Sponsor として参加しました - Medley Developer Blog" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fdeveloper.medley.jp%2Fentry%2F2017%2F09%2F28%2F120000" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="/entry/2017/09/28/120000">developer.medley.jp</a></cite>
    <h2>{`2018 年のレポート`}</h2>
    <iframe className="embed-card embed-blogcard" style={{
      "display": "block",
      "width": "100%",
      "height": "190px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="Lightning Talks Sponsor として RubyKaigi 2018 に参加してきました - Medley Developer Blog" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fdeveloper.medley.jp%2Fentry%2F2018%2F06%2F06%2F151300" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="entry/2018/06/06/151300">developer.medley.jp</a></cite>
    <p>{`2019 年は福岡県の福岡国際会議場での開催でした。会場に向かう途中の博多駅ではさっそく RubyKaigi のポスターに迎えられ、RubyKaigi ムードが高まっておりました。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "博多駅ではさっそく RubyKaigi のポスターがお出迎え"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424131901.jpg",
        "alt": "RubyKaigi"
      }}></img>
      <figcaption parentName="figure">{`博多駅ではさっそく RubyKaigi のポスターがお出迎え`}</figcaption>
    </figure>
    <p>{`ということで、今年の RubyKaigi の様子をレポートさせていただきます。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="【福岡国際会議場】" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.marinemesse.or.jp%2Fcongress%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.marinemesse.or.jp/congress/">www.marinemesse.or.jp</a></cite>
    <iframe style={{
      "border": "0"
    }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.0337958381538!2d130.4012956152033!3d33.60442758073009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354191ef7124343b%3A0x68f0d80606e3c997!2z56aP5bKh5Zu96Zqb5Lya6K2w5aC0!5e0!3m2!1sja!2sjp!4v1556083242524!5m2!1sja!2sjp" width="600" height="450" frameBorder="0" allowFullScreen=""></iframe>
    <h1>{`Breakfast Sponsor の様子`}</h1>
    <p>{`今回は Breakfast Sponsor としての協賛で、RubyKaigi2 日目と 3 日目の朝 8 時 30 分から 10 時までの時間帯で、RubyKaigi に参加されるみなさんに朝食をご提供させていただきました。`}</p>
    <p>{`朝食会場は福岡国際会議場内の 1F にあるレストラン `}<a parentName="p" {...{
        "href": "https://www.f-sunpalace.com/restaurant/raconter/#raconter%E2%80%8B"
      }}>{`ラコンテ`}</a>{`さん。朝食の内容はビュッフェ形式で、焼き魚やオムレツなどの定番おかずと、福岡名産の辛子明太子や辛子高菜をたらふく食べられる朝食でした。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "入り口でみなさんをお出迎えするエンジニア稲本"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424132555.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`入り口でみなさんをお出迎えするエンジニア稲本`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "おしゃれなテラス席"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424132612.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`おしゃれなテラス席`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "ビュッフェに並ぶおかず"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424132701.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`ビュッフェに並ぶおかず`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "フルーツもたくさん"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424132608.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`フルーツもたくさん`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "辛子明太 & 辛子高菜」乗せごはん"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424132602.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`「辛子明太 & 辛子高菜」乗せごはん`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "福岡名産の辛子明太子と辛子高菜を白ごはんに添えてたらふくいただきました"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424132651.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`福岡名産の辛子明太子と辛子高菜を白ごはんに添えてたらふくいただきました`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "会場の様子、大盛況"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424132735.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`会場の様子、大盛況でした`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "テラス席はグローバルな感じ"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424132743.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`テラス席はグローバルな感じ`}</figcaption>
    </figure>
    <p>{`Twitter でも朝食の様子を話題にしていただけたりと、ご満足いただけた様子が伺えました。`}</p>
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">MEDLEY さんの朝食サービス、めっちゃメニューある。外で優雅に食べてます</p>&mdash; Tsukasa OISHI (@tsukasa_oishi) <a href="https://twitter.com/tsukasa_oishi/status/1119022532697268224?ref_src=twsrc%5Etfw">April 18, 2019</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">MEDLEY さん提供の朝食はブュッフェ形式。洋風にしてみたけど、他にご飯や味噌汁もある。 <a href="https://twitter.com/hashtag/rubykaigi?src=hash&amp;ref_src=twsrc%5Etfw">#rubykaigi</a> <a href="https://t.co/W4eggL6PQt">pic.twitter.com/W4eggL6PQt</a></p>&mdash; thinca (@thinca) <a href="https://twitter.com/thinca/status/1119028472507015168?ref_src=twsrc%5Etfw">April 19, 2019</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">RubyKaigi と MEDLEY さんのおかげで健康的な生活してる…。<a href="https://twitter.com/hashtag/rubykaigi?src=hash&amp;ref_src=twsrc%5Etfw">#rubykaigi</a> <a href="https://t.co/Z502pk3yKg">pic.twitter.com/Z502pk3yKg</a></p>&mdash; yebis0942 (@yebis0942) <a href="https://twitter.com/yebis0942/status/1119078057518571520?ref_src=twsrc%5Etfw">April 19, 2019</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <blockquote className="twitter-tweet"><p lang="ja" dir="ltr">朝食で食べた明太子・高菜ご飯がおいしかった！ MEDLEY さん、ありがとうございます！ <a href="https://twitter.com/hashtag/rubykaigi?src=hash&amp;ref_src=twsrc%5Etfw">#rubykaigi</a></p>&mdash; snagasawa (@snagasawa_) <a href="https://twitter.com/snagasawa_/status/1119393835048558592?ref_src=twsrc%5Etfw">April 20, 2019</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
    <p>{`ご来場いただいた人数は、RubyKaigi2 日目（朝食 1 日目）は 379 名、3 日目（朝食 2 日目）は 411 名でした。`}</p>
    <p>{`朝食会場の出入り口でご案内していると「美味しかったです、ありがとうございました！」というお声を直接いただけたりと、みなさんにご満足いただけたようで良かったです。`}</p>
    <p>{`Breakfast Sponsor での参加は初めてでしたが、みなさんが満足気に朝食会場を後にする姿を見ているとこちらも嬉しい気持ちになりました。`}</p>
    <p>{`朝食をご利用いただいたみなさん、本当にありがとうございました。`}</p>
    <p>{`今年は Breakfast Sponsor という形でしたが、Ruby と Ruby コミュニティの発展のため、今後も様々な形で貢献していければと思います。`}</p>
    <h1>{`RubyKaigi 2019 の様子`}</h1>
    <p>{`今年も毎年恒例の Matz さんの Keynote から始まりました。`}</p>
    <p>{`今年は「The Year of Concurrency」というテーマで、Ruby 3 についての発表でした。`}</p>
    <p>{`Ruby 3 の Static Analysis、Performance、Concurrency あたりの話を中心に、Ruby の今後の展望について語られました。普段 Ruby を使って仕事をしている身として、Matz さんから Ruby 3 の話を聞けたのは貴重な時間でしたし、改めて Ruby の進化を実感できるセッションでした。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "Matz さんの Keynote"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424133336.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`Matz さんの Keynote`}</figcaption>
    </figure>
    <p>{`その他のセッションスケジュールについてはこちら。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="RubyKaigi 2019" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Frubykaigi.org%2F2019%2Fschedule" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://rubykaigi.org/2019/schedule">rubykaigi.org</a></cite>
    <p>{`2 階にはブースゾーンがあり、セッションの合間にはたくさんの参加者で溢れかえっていました。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "ブースゾーンは大盛況"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424133441.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`ブースゾーンは大盛況`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "ブースゾーンの出入り口付近には各社のノベルティ"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424133642.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`ブースゾーンの出入り口付近には各社のノベルティ`}</figcaption>
    </figure>
    <undefined><figure {...{
        "className": "figure-image figure-image-fotolife mceNonEditable",
        "title": "Ruby 関連書籍の展示も"
      }}>
        <img parentName="figure" {...{
          "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424133523.jpg",
          "alt": ""
        }}></img>
        <figcaption parentName="figure">{`Ruby 関連書籍の展示も`}</figcaption>
      </figure>{`
会場 5 階（最上階）にはハックスペースが用意されていました。`}</undefined>
    <p>{`ここで各々休憩したり、黙々と作業したり、仲間と談笑したりと、様々な用途に使われていました。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "ハックスペース入り口"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424133847.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`ハックスペース入り口`}</figcaption>
    </figure>
    <undefined><figure {...{
        "className": "figure-image figure-image-fotolife mceNonEditable",
        "title": " ハックスペースの中（このときはセッション中だったので空いてました）"
      }}>
        <img parentName="figure" {...{
          "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424133922.jpg",
          "alt": ""
        }}></img>
        <figcaption parentName="figure">{`ハックスペースの中（このときはセッション中だったので空いてました）`}</figcaption>
      </figure>{`
福岡開催ならではの屋台スペースも用意されていました。`}</undefined>
    <p>{`ランチタイムには屋台ラーメンなどが振る舞われ、めちゃくちゃ混み合っていましたが、こういう地域特有の催しは RubyKaigi を盛り上げてくれるので良いですね。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "ランチ前になるとゾロゾロと人集りが"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424134029.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`ランチ前になるとゾロゾロと人集りが`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "ランチタイムの屋台は大行列"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424134059.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`ランチタイムの屋台は大行列`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "屋台でラーメンを食らう参加者を羨むエンジニア中畑"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424134120.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`屋台でラーメンを食らう参加者を羨むエンジニア中畑`}</figcaption>
    </figure>
    <h1>{`メドレーブースの様子`}</h1>
    <p>{`また、2 階のブースゾーンにはメドレーのブースも出展しました。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "今回参加したメンバー全員で"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424134208.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`今回参加したメンバー全員で`}</figcaption>
    </figure>
    <p>{`ノベルティーにはメドレーロゴ付きのうちわ、ステッカー、お水、そして恒例の絆創膏を用意しました。`}</p>
    <p>{`RubyKaigi 2018 でもらったメドレーの絆創膏が、子供が怪我したときに役に立ったよ〜という嬉しいお声をいただいたりもしました。`}</p>
    <p>{`靴擦れや擦り傷を負ったとき、お子さんが転んだときとかに役に立つので、今度見かけた際はぜひお持ち帰りください。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "メドレーの絆創膏"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424134245.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`メドレーの絆創膏`}</figcaption>
    </figure>
    <p>{`ブースではメドレーの会社説明やメドレーが提供するサービスの説明などを行いました。`}</p>
    <p>{`スポンサーとして初参加だった RubyKaigi 2017 に参加したときよりもメドレーのことを知っている人が増えているなあと実感しました。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "ブースで対応するエンジニア達（中畑、稲本、橋本）"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424134320.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`ブースで対応するエンジニア達（中畑、稲本、橋本）`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "ブースで対応するエンジニア達（中畑、稲本、橋本）"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424134405.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`Matz さんにも来ていただきました！`}</figcaption>
    </figure>
    <h1>{`福岡の様子`}</h1>
    <p>{`そして福岡といえば美味しいごはんですよね。`}</p>
    <p>{`1 日目の夜は`}<strong parentName="p">{`RubyKaigi 2019 Official Party`}</strong>{`が開催され、中洲川端商店街を半貸切にして日本酒やうどん、ラーメンや地鶏などが振る舞われました。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "RubyKaigi 2019 Official Party の会場・中洲川端商店街の Guide Map"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424134516.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`RubyKaigi 2019 Official Party の会場・中洲川端商店街の Guide Map`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "大樽に入って日本酒が配られてました"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424134552.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`大樽に入って日本酒が配られてました`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "日本酒のラインナップ"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424134633.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`日本酒のラインナップ`}</figcaption>
    </figure>
    <figure className="figure-image figure-image-fotolife mceNonEditable" title="うどんを前に満面の笑みの広報・深澤">
![f:id:medley_inc:20190424143021j:plain](https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424143021.jpg "f:id:medley_inc:20190424143021j:plain")
      <figcaption>うどんを前に満面の笑みの広報・深澤</figcaption>
    </figure>
夜は福岡の美食を求めて街に繰り出しました。
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "絶品イカの活造り"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424135955.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`絶品イカの活造り`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "定番モツ鍋"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424140028.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`定番モツ鍋`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "出汁が最高の水炊き"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424140056.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`出汁が最高の水炊き`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "会場近くの某有名ラーメン店のきくらげラーメン"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424140125.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`会場近くの某有名ラーメン店のきくらげラーメン`}</figcaption>
    </figure>
    <undefined><figure {...{
        "className": "figure-image figure-image-fotolife mceNonEditable",
        "title": "あまりのうまさに昇天するエンジニア中畑"
      }}>
        <img parentName="figure" {...{
          "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424140152.jpg",
          "alt": ""
        }}></img>
        <figcaption parentName="figure">{`あまりのうまさに昇天するエンジニア中畑`}</figcaption>
      </figure>{`
また、RubyKaigi 初参加の 2017 年から続けている神社参拝にも行ってきました。`}</undefined>
    <p>{`今回は警固神社（けごじんじゃ）に参拝してきました。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="宗教法人 警固神社" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fkegojinja.or.jp%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://kegojinja.or.jp/">kegojinja.or.jp</a></cite>
    <iframe style={{
      "border": "0"
    }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3323.6747705996254!2d130.3977272!3d33.5877926!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354191855856aaab%3A0x1a1ab9a2c3e2cfa5!2z6K2m5Zu656We56S-!5e0!3m2!1sja!2sjp!4v1556083915959!5m2!1sja!2sjp" width="600" height="450" frameBorder="0" allowFullScreen=""></iframe>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "警固神社入り口"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424140248.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`警固神社入り口`}</figcaption>
    </figure>
    <figure {...{
      "className": "figure-image figure-image-fotolife mceNonEditable",
      "title": "参拝するエンジニア中畑"
    }}>
      <img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190424/20190424140311.jpg",
        "alt": ""
      }}></img>
      <figcaption parentName="figure">{`参拝するエンジニア中畑`}</figcaption>
    </figure>
    <h1>{`さいごに`}</h1>
    <p>{`2017 年から 3 度目となる RubyKaigi の協賛の様子をお届けしました。`}</p>
    <p>{`Breakfast Sponsor として、RubyKaigi 参加者のみなさんにご満足いただけて本当に良かったなあと思います。`}</p>
    <p>{`繰り返しになりますが、Ruby と Ruby コミュニティの発展のため、今後も様々な形で貢献していければと思います。`}</p>
    <p>{`来年 2020 年は長野県松本市での開催ということで、またみなさんとお会いできることを願って、レポートを締めたいと思います。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="RubyKaigi 2020" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Frubykaigi.org%2F2020" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://rubykaigi.org/2020">rubykaigi.org</a></cite>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは医療業界に存在する課題に IT を駆使して取り組んでいきたいメンバーを、デザイナー・エンジニアを中心に全職種絶賛募集中です。皆さまからのご応募お待ちしております。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="MEDLEY DESIGN&ENGINEERING" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      